export function runNum (str, fixed) {
  let _s = Number(str)
  if (_s + '' === 'NaN') {
    _s = 0
  }
  if (/^[0-9]*[1-9][0-9]*$/.test(fixed)) {
    _s = _s.toFixed(fixed)
    const rs = _s.indexOf('.')
    if (rs < 0) {
      _s += '.'
      for (let i = 0; i < fixed; i++) {
        _s += '0'
      }
    }
  }
  return _s
}

export function jsonStringify (json, defaultVal) {
  try {
    return JSON.stringify(json)
  } catch (e) {
    return defaultVal || ''
  }
}

// export function jsonParse (str, defaultVal) {
//   if (str !== null && typeof str === 'object') {
//     return str
//   }
//   try {
//     return JSON.parse(str)
//   } catch (e) {
//     return defaultVal || {}
//   }
// }

export function formatMobile (phone) {
  if (count(phone) === 0) {
    return ''
  }
  return phone.substring(0, 3) + '****' + phone.substring(phone.length - 4)
}

function count (obj) {
  try {
    if (typeof obj === 'undefined') {
      return 0
    }
    if (typeof obj === 'number') {
      obj += ''
    }
    if (typeof obj.length === 'number') {
      return obj.length
    } else {
      let i = 0
      for (var key in obj) {
        
        i++
      }
      return i
    }
  } catch (e) {
    return 0
  }
}

export function randomNum (n) {
  var r = ''
  for (var i = 0; i < n; i++) {
    r += Math.floor(Math.random() * 10)
  }
  return r
}
