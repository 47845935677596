import request, { $post } from '@/utils/request'

// 获取历史聊天记录
export const historyMessage = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/message/get',
    params: data
  })
}

// 保存聊天记录
export const saveMessage = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/message/send',
    params: data
  })
}

export const getListCom = (url, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await $post(url, params, {})
      const { ret, msg, data } = res.data
      if (ret == 1) {
        resolve(data)
        return
      }
      reject(msg)
    } catch (error) { }
  })
}


